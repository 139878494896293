// LogoutButton.js
import { Typography } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import { useTheme } from "@mui/material/styles";

const LogoutButton = () => {
  const theme = useTheme();
  const { logout } = useAuth();

  return (
    <Typography
      variant="body1"
      sx={{
        color: theme.palette.primary.main,
        cursor: "pointer",
        textDecoration: "underline",
      }}
      onClick={logout}
    >
      Log out
    </Typography>
  );
};

export default LogoutButton;
