// services/geolocationService.js
export const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve({ latitude, longitude });
        },
        (error) => {
          reject(
            "Unable to retrieve location. Please enable location services."
          );
        }
      );
    } else {
      reject("Geolocation is not supported by your browser.");
    }
  });
};
