import React, { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import {
  TextField,
  Box,
  ClickAwayListener,
  InputAdornment,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

function DateRangePicker() {
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);

  const formattedStartDate = state[0].startDate
    ? format(state[0].startDate, "MM/dd/yyyy")
    : "";
  const formattedEndDate = state[0].endDate
    ? format(state[0].endDate, "MM/dd/yyyy")
    : "";

  const handleClickAway = () => {
    setShowCalendar(false);
  };

  const handleTextFieldClick = () => {
    setShowCalendar((prev) => !prev);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <TextField
        variant="outlined"
        size="small"
        value={
          formattedStartDate && formattedEndDate
            ? `${formattedStartDate} - ${formattedEndDate}`
            : "MM/dd/yyyy - MM/dd/yyyy"
        }
        onClick={handleTextFieldClick}
        readOnly
        fullWidth
        sx={{
          backgroundColor: "#f5f5f5",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalendarTodayIcon />
            </InputAdornment>
          ),
        }}
      />
      {showCalendar && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            sx={{
              position: "absolute",
              zIndex: 1,
              right: 0,
              mt: 1,
              boxShadow: 3,
              backgroundColor: "background.paper",
            }}
          >
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={state}
            />
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
}

export default DateRangePicker;
