import React, { createContext, useContext, useEffect, useState } from "react";
import useCrud from "../hooks/useCrud";
import { getMenus } from "../services/menuService";
import { getItemsByMenuId } from "../services/itemService";
import { useLoading } from "./LoadingContext";
import { extractUniqueCategories } from "../utils/categoryUtils";
import { filterItemsByCategory as filterItemsByCategoryUtil } from "../utils/itemUtils";

const DashboardContext = createContext();

export const useDashboard = () => useContext(DashboardContext);

export const DashboardProvider = ({ children }) => {
  const [items, setItems] = useState([]);
  const [unusedCategories, setUnusedCategories] = useState([]);
  const [menus, setMenus] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeMenu, setActiveMenu] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [error, setError] = useState(null);
  const { startLoading, stopLoading, isLoading } = useLoading();
  const { fetchData: fetchItemNames } = useCrud("/items");
  const { fetchData: fetchUnusedCategories } = useCrud("/categories/unused");
  const { fetchData: fetchCurrencies } = useCrud("/currencies");
  const { fetchData: fetchLanguages } = useCrud("/languages");

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        // Fetch items, unused categories, and menus concurrently
        const [
          allItems,
          allCategories,
          menusResponse,
          currenciesResponse,
          languagesResponse,
        ] = await Promise.all([
          fetchItemNames(),
          fetchUnusedCategories(),
          getMenus(),
          fetchCurrencies(),
          fetchLanguages(),
        ]);
        setItems(allItems);
        setUnusedCategories(allCategories);
        setMenus(menusResponse.data);
        setCurrencies(currenciesResponse);
        setLanguages(languagesResponse);

        if (menusResponse.data.length > 0) {
          setActiveMenu(menusResponse.data[0].id);
          const items = await fetchItemsByMenuId(menusResponse.data[0].id);
          setItems(items);
          setUniqueCategories(items);
        }
      } catch (error) {
        setError("Failed to load data");
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, []);

  const fetchItemsByMenuId = async (id) => {
    try {
      const response = await getItemsByMenuId(id);
      setItems(response.data);
      return response.data;
    } catch (error) {
      setError("Failed to load items");
    }
  };

  const setUniqueCategories = (items) => {
    const uniqueCategories = extractUniqueCategories(items);
    if (uniqueCategories.length > 0) {
      setCategories(uniqueCategories);
      setSelectedCategory(uniqueCategories[0]);
      filterItemsByCategory(items, uniqueCategories[0].id);
    } else {
      console.log("No unique categories found.");
    }
  };

  const filterItemsByCategory = (itemsToFilter = items, categoryId) => {
    const filtered = filterItemsByCategoryUtil(itemsToFilter, categoryId);
    setFilteredItems(filtered);
  };

  const handleMenuClick = async (id) => {
    setActiveMenu(id);
    startLoading();
    try {
      const { data: items } = await getItemsByMenuId(id);
      setItems(items);
      setUniqueCategories(items);
    } catch (error) {
      setError("Failed to load items");
    } finally {
      stopLoading();
    }
  };

  const handleCategoryClick = (id) => {
    const selected = categories.find((category) => category.id === id);
    if (selected) {
      setSelectedCategory(selected);
      filterItemsByCategory(items, id);
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        items,
        unusedCategories,
        menus,
        filteredItems,
        categories,
        activeMenu,
        selectedCategory,
        handleMenuClick,
        handleCategoryClick,
        error,
        isLoading,
        currencies,
        languages,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
