import { useAuth } from "../../contexts/AuthContext";
import { isImageUrl } from "../../utils/isImageUrl";
import HolomenuTitle from "../../components/holomenuTitle";
import LazyImage from "../../components/lazyImage";
import { useTheme } from "@mui/material";

const RestaurantLogo = () => {
  const { isAuthenticated, restaurantLogo, auth, splashScreenBg } = useAuth();
  const theme = useTheme();
  if (!isAuthenticated) return;
  return (
    <>
      {isImageUrl(restaurantLogo) ? (
        <LazyImage
          src={restaurantLogo}
          alt="Restaurant Logo"
          sx={{
            width: { xs: "230px", md: "290px" },
            marginBottom: { xs: 4, md: 6 },
          }}
        />
      ) : (
        <HolomenuTitle
          title={auth?.user?.restaurant?.restaurant_name}
          style={{
            maxWidth: "600px",
            color: isImageUrl(splashScreenBg)
              ? "white"
              : theme.palette.primary.main,
          }}
        />
      )}
    </>
  );
};

export default RestaurantLogo;
