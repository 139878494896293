import React, { useState, useRef, useEffect } from "react";
import { Box } from "@mui/material";
import RestaurantLogo from "./restaurantLogo";
import MenuButton from "./menuButton";
import ButtonsGroup from "./buttonsGroup";
import LoginAdminModel from "./loginAdminModel";
import { useAuth } from "../../contexts/AuthContext";
import { isImageUrl } from "../../utils/isImageUrl";

const Startup = () => {
  const [isInView, setIsInView] = useState(false);
  const [isAdminLoginOpen, setIsAdminLoginOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { loginAdminUser, splashScreenBg } = useAuth();
  const ref = useRef();

  const handleOpenAdminLogin = () => setIsAdminLoginOpen(true);
  const handleCloseAdminLogin = () => setIsAdminLoginOpen(false);

  const handleAdminLogin = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await loginAdminUser(username, password);
    } catch (error) {
      setError(error.message || "Login failed. Please try again.");
    }
    if (!error) handleCloseAdminLogin();
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
        }
      },
      { threshold: 0.1 }
    );
    if (ref.current) observer.observe(ref.current);
    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, []);

  return (
    <Box
      ref={ref}
      sx={{
        height: "100svh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: isImageUrl(splashScreenBg)
          ? "transparent"
          : splashScreenBg,
        backgroundImage:
          isInView && isImageUrl(splashScreenBg) // Lazy load the background image
            ? `url(${splashScreenBg})`
            : "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
        transition: "background-image 0.5s ease-in-out", // Smooth transition
      }}
    >
      <RestaurantLogo />
      <MenuButton />
      <ButtonsGroup handleOpenAdminLogin={handleOpenAdminLogin} />
      <LoginAdminModel
        handleCloseAdminLogin={handleCloseAdminLogin}
        isAdminLoginOpen={isAdminLoginOpen}
        handleAdminLogin={handleAdminLogin}
        username={username}
        setUsername={setUsername}
        password={password}
        setPassword={setPassword}
        error={error}
      />
    </Box>
  );
};

export default Startup;
