import React, { createContext, useState, useContext } from "react";

// Create a context for loading state
const LoadingContext = createContext();

// Create a custom hook to use the loading context
export const useLoading = () => useContext(LoadingContext);

// Loading provider to manage loading state globally
export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);

  return (
    <LoadingContext.Provider value={{ isLoading, startLoading, stopLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};
