import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const LoginAdminModel = ({
  isAdminLoginOpen,
  handleCloseAdminLogin,
  handleAdminLogin,
  username,
  setUsername,
  password,
  setPassword,
  error,
}) => {
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const handleClickShowPassword = () => setShowPassword(!showPassword); // Toggle show password
  const handleMouseDownPassword = (event) => event.preventDefault(); // Prevent default behavior on mouse down

  return (
    <Modal open={isAdminLoginOpen} onClose={handleCloseAdminLogin}>
      <Box
        component="form"
        onSubmit={handleAdminLogin}
        sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          maxWidth: "350px",
          backgroundColor: "white",
          margin: "auto",
          borderRadius: "8px",
          mt: "15%",
          boxShadow: 24,
        }}
      >
        <Typography variant="h5" fontWeight="bold" mb={3}>
          Login as admin
        </Typography>

        <Typography variant="body2" color="textSecondary" mb={3}>
          Sign in with your username and password
        </Typography>

        {/* Username Field */}
        <TextField
          fullWidth
          margin="normal"
          required
          id="username"
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        {/* Password Field with Toggle */}
        <TextField
          fullWidth
          margin="normal"
          required
          id="password"
          label="Password"
          type={showPassword ? "text" : "password"} // Toggle between 'text' and 'password' types
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  sx={{ color: "gray" }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {/* Error Message */}
        {error && (
          <Typography color="error" mt={2}>
            {error}
          </Typography>
        )}

        {/* Login Button */}
        <Button
          type="submit"
          variant="contained"
          sx={{
            mt: 5,
            mb: 2,
            color: "white",
            fontWeight: "bold",
            width: "100%",
            height: "45px",
          }}
        >
          Login
        </Button>
      </Box>
    </Modal>
  );
};

export default LoginAdminModel;
