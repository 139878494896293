// ThemeProvider.js
import React, { createContext, useContext, useState } from "react";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";

// Define custom properties
export const borderRadius = "0.5rem";
export const defaultPrimaryColor = "#48664e"; // Moon green color
export const defaultSecondaryColor = "#f5f3df"; // Light cream color
export const defaultTertiaryColor = "#EA8C26";

// Create the default theme
const createAppTheme = (
  primaryColor = defaultPrimaryColor,
  secondaryColor = defaultSecondaryColor,
  tertiaryColor = defaultTertiaryColor,
  fontUrl = null
) => {
  console.log(fontUrl);

  const fontFace = fontUrl
    ? {
        "@font-face": {
          fontFamily: "CustomFont",
          src: `url(${fontUrl}) format('truetype')`,
        },
      }
    : {};
  return createTheme({
    palette: {
      primary: { main: primaryColor },
      secondary: { main: secondaryColor },
      tertiary: { main: tertiaryColor },
    },
    shape: {
      borderRadius, // Global border-radius
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          ...fontFace,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius,
            textTransform: "none",
            transition: "all 0.3s ease-in-out",
            "&.inactive": {
              backgroundColor: "#fff",
              border: "none",
              color: "#333",
            },
          },
          contained: {
            backgroundColor: primaryColor,
            color: secondaryColor,
            "&:hover": {
              backgroundColor: primaryColor,
              color: secondaryColor,
              opacity: 0.85, // Slight transparency effect on hover
            },
          },
          // Outlined variant
          outlined: {
            border: `1px solid ${primaryColor}`,
            color: primaryColor,
            // "&:hover": {
            //   backgroundColor: `${primaryColor}10`, // Light tint of primary color
            //   borderColor: primaryColor,
            //   color: primaryColor,
            // },
          },
          // Text variant
          text: {
            color: primaryColor,
            "&:hover": {
              backgroundColor: `${primaryColor}10`, // Light tint of primary color on hover
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            border: `2px solid ${secondaryColor}`,
            color: secondaryColor,
            width: "43px",
            height: "43px",
            fontSize: "23px",
            "&:hover": {
              backgroundColor: secondaryColor,
              color: primaryColor,
            },
            "&.reverse": {
              color: primaryColor,
              border: `2px solid ${primaryColor}`,
              "&:hover": {
                backgroundColor: primaryColor,
                color: secondaryColor,
              },
            },
            "&.contained": {
              backgroundColor: primaryColor,
              border: `2px solid ${primaryColor}`,
              color: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: primaryColor,
              },
            },
            "&.admin-btn": {
              backgroundColor: "#000",
              color: "#fff",
              border: `2px solid #fff`,
              "&:hover": {
                border: `2px solid #fff`,
                backgroundColor: "rgba(255, 255, 255, 0)",
              },
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: "23px",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            borderRadius,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: primaryColor,
              },
              "&:hover fieldset": {
                borderColor: primaryColor,
              },
              "&.Mui-focused fieldset": {
                borderColor: primaryColor,
              },
            },
          },
        },
      },
    },
    typography: {
      fontFamily: fontUrl
        ? "CustomFont, Arial, sans-serif"
        : "Roboto, Arial, sans-serif", // Use CustomFont if available, else fallback to MUI default fonts
      fontSize: 14,
      h1: { fontSize: "3rem" },
      h2: { fontSize: "2rem" },
      h3: { fontSize: "1.5rem" },
      h4: { fontSize: "1.125rem" },
      h6: { fontSize: "1rem" },
      body1: { fontSize: "0.875rem" },
      body2: { fontSize: "0.875rem", color: "black" },
      button: { fontSize: "0.875rem", textTransform: "none" },
      caption: { fontSize: "0.875rem", color: "black" },
    },
  });
};
// ThemeContext setup
const ThemeContext = createContext();

// Custom hook to use ThemeContext
export const useThemeContext = () => useContext(ThemeContext);

// ThemeProvider component
export const CustomThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(createAppTheme());

  const updateTheme = (
    primaryColor,
    secondaryColor,
    tertiaryColor,
    fontUrl
  ) => {
    setTheme((prevTheme) => {
      const newTheme = createAppTheme(
        primaryColor,
        secondaryColor,
        tertiaryColor,
        fontUrl
      );
      if (prevTheme.typography.fontFamily !== newTheme.typography.fontFamily) {
        return newTheme;
      }
      return prevTheme;
    });
  };

  return (
    <ThemeContext.Provider value={{ updateTheme }}>
      <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
    </ThemeContext.Provider>
  );
};

export default CustomThemeProvider;
