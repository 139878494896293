// ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { MenuProvider } from "../contexts/MenuContext";
import { CartProvider } from "../contexts/CartContext";
import { ActivityProvider } from "../contexts/ActivityContext";
import { CurrencyProvider } from "../contexts/CurrencyContext";
import { DashboardProvider } from "../contexts/DashboardProvider";

const providersMap = {
  MenuProvider,
  CartProvider,
  ActivityProvider,
  CurrencyProvider,
  DashboardProvider,
};

const ProtectedRoute = ({
  element,
  allowedRoles,
  providers = [],
  redirectTo,
}) => {
  const { isAuthenticated, auth } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (allowedRoles && !allowedRoles.includes(auth.role)) {
    return <Navigate to={redirectTo} replace />;
  }

  // Wrap element in providers if specified
  const wrappedElement = providers.reduce((acc, provider) => {
    const ProviderComponent = providersMap[provider];
    return ProviderComponent ? (
      <ProviderComponent>{acc}</ProviderComponent>
    ) : (
      acc
    );
  }, element);

  return wrappedElement;
};

export default ProtectedRoute;
