import { TextField, Button, Box, Typography, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const LoginForm = ({
  restaurantName,
  setRestaurantName,
  handleLogin,
  isLoading,
  error,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        padding: {
          xs: 2,
          sm: 4,
        },
        borderRadius: theme.shape.borderRadius,
        boxShadow: 3,
        maxWidth: {
          xs: "90%",
          sm: 400,
        },
        width: "100%",
        textAlign: "center",
        zIndex: 1, // Ensure content is above the background
      }}
    >
      {/* Welcome Text */}
      <Typography variant="h5" fontWeight="bold" mb={1}>
        Welcome!
      </Typography>
      <Typography variant="body2" color="textSecondary" mb={3}>
        Sign in with your restaurant name
      </Typography>

      {/* Error Message */}
      {error && <Typography color="error">{error}</Typography>}

      {/* Form */}
      <Box
        component="form"
        onSubmit={(e) => {
          if (e) e.preventDefault();
          handleLogin();
        }}
      >
        <TextField
          fullWidth
          margin="normal"
          required
          id="restaurantName"
          label="Restaurant Name"
          value={restaurantName}
          onChange={(e) => setRestaurantName(e.target.value)}
        />

        {/* Login Button */}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 2,
            mb: 3,
            height: "45px",
            fontWeight: "bold",
          }}
          disabled={isLoading}
        >
          {isLoading ? "Logging in..." : "Login"}
        </Button>
      </Box>

      {/* OR Divider */}
      <Divider>or</Divider>

      {/* Access Demo Button */}
      <Button
        fullWidth
        variant="outlined"
        sx={{
          mt: 3,
          height: "45px",
          fontWeight: "bold",
        }}
      >
        Access Demo
      </Button>
    </Box>
  );
};

export default LoginForm;
