import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  TextField,
  Button,
  Box,
  CircularProgress,
  Autocomplete,
  Chip,
  Grid2 as Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";
import ImageUpload from "../ImageUpload";
import { useDashboard } from "../../contexts/DashboardProvider";
import useCrud from "../../hooks/useCrud";
import { useAuth } from "../../contexts/AuthContext";
import ColorPickerField from "../ColorPickerField";
import FileUploadField from "../FileUploadField";
import CloseDialogButton from "../CloseDialogButton";

const initialState = {
  restaurant_name: "",
  logo: "",
  address: "",
  phone: "",
  email: "",
  instagram_link: "",
  youtube_link: "",
  facebook_link: "",
  currency_id: "",
  languages: [],
  themeData: {
    primary_color: "",
    secondary_color: "",
    tertiary_color: "",
    splash_screen_bg: "",
    theme_bg: "",
    font_url: "",
    font_name: "",
  },
  fontsData: [],
};

const SettingsForm = ({ open, onClose }) => {
  const { auth } = useAuth();
  const { languages, currencies } = useDashboard();
  const { getDataById, updateData, loading, fieldErrors } =
    useCrud("/restaurants");
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState({ ...initialState });

  useEffect(() => {
    const loadRestaurant = async () => {
      if (!auth?.user?.restaurant?.id) return;
      try {
        const restaurant = await getDataById(auth.user.restaurant.id);
        setFormData({
          restaurant_name: restaurant.restaurant_name,
          logo: restaurant.logo,
          address: restaurant.address,
          phone: restaurant.phone,
          email: restaurant.email,
          instagram_link: restaurant.instagram_link,
          youtube_link: restaurant.youtube_link,
          facebook_link: restaurant.facebook_link,
          currency_id: restaurant.currency_id,
          languages: restaurant.languages.map((lang) => lang.language_id),
          themeData: {
            primary_color: restaurant.theme.primary_color,
            secondary_color: restaurant.theme.secondary_color,
            tertiary_color: restaurant.theme.tertiary_color,
            splash_screen_bg: restaurant.theme.splash_screen_bg,
            theme_bg: restaurant.theme.theme_bg,
            font_url: restaurant.theme.font_url,
            font_name: restaurant.theme.font_name,
          },
          fontsData: restaurant.theme.fonts
            ? restaurant.theme.fonts.map((font) => ({
                language_id: font.language,
                font_name: font.font_name,
                font_url: font.font_url,
              }))
            : [],
        });
      } catch (error) {
        console.error("Error fetching restaurant details:", error);
      }
    };
    if (open) loadRestaurant();
    else handleReset();
  }, [open]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleThemeChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      themeData: {
        ...prevData.themeData,
        [field]: value,
      },
    }));
  };

  const handleFontChange = (languageId, fileName, fileUrl) => {
    setFormData((prevData) => ({
      ...prevData,
      fontsData: [
        ...prevData.fontsData.filter((f) => f.language_id !== languageId),
        ...(fileUrl
          ? [
              {
                language_id: languageId,
                font_name: fileName,
                font_url: fileUrl,
              },
            ]
          : []),
      ],
    }));
  };

  const handleImageUpload = (imageUrl, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: imageUrl,
    }));
  };

  const handleSave = async () => {
    try {
      await updateData(auth.user.restaurant.id, formData);
      handleClose();
    } catch (error) {
      console.error("Error updating restaurant:", error);
    }
  };

  const handleClose = () => {
    onClose();
    handleReset();
  };

  const handleReset = () => {
    setActiveTab(0);
    setFormData({ ...initialState });
  };

  return (
    <Dialog
      open={open}
      onClose={loading ? undefined : handleClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        Settings
        <CloseDialogButton onClose={handleClose} loading={loading} />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Edit restaurant" sx={{ flex: 1 }} />
            <Tab label="Edit theme" sx={{ flex: 1 }} />
          </Tabs>
        </Box>

        {activeTab === 0 ? (
          <Box>
            <Grid container spacing={2}>
              <Grid size={4}>
                <TextField
                  fullWidth
                  label="Restaurant name"
                  value={formData.restaurant_name}
                  onChange={(e) =>
                    handleChange("restaurant_name", e.target.value)
                  }
                  margin="normal"
                  required
                  error={Boolean(fieldErrors?.restaurant_name)}
                  helperText={fieldErrors?.restaurant_name}
                />
                <TextField
                  fullWidth
                  label="Phone"
                  value={formData.phone}
                  onChange={(e) => handleChange("phone", e.target.value)}
                  margin="normal"
                  required
                  error={Boolean(fieldErrors?.phone)}
                  helperText={fieldErrors?.phone}
                />
              </Grid>
              <Grid size={4}>
                <TextField
                  fullWidth
                  label="Email"
                  value={formData.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                  margin="normal"
                  required
                  error={Boolean(fieldErrors?.email)}
                  helperText={fieldErrors?.email}
                />
                <TextField
                  fullWidth
                  label="Website"
                  value={formData.address}
                  onChange={(e) => handleChange("address", e.target.value)}
                  margin="normal"
                  error={Boolean(fieldErrors?.address)}
                  helperText={fieldErrors?.address}
                />
              </Grid>
              <Grid size={4}>
                <ImageUpload
                  imageUrl={formData.logo}
                  onImageUpload={(url) => handleImageUpload(url, "logo")}
                  disabled={loading}
                  sxImg={{ objectFit: "contain" }}
                  uploadLabel="svg, png Logo"
                  sxBox={{ mb: 0 }}
                />
              </Grid>
              <Grid size={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="currency-label">Currency</InputLabel>
                  <Select
                    labelId="currency-label"
                    value={formData.currency_id}
                    onChange={(e) =>
                      handleChange("currency_id", e.target.value)
                    }
                    label="currency"
                    error={Boolean(fieldErrors?.currency_id)}
                  >
                    {currencies.map((currency) => (
                      <MenuItem key={currency.id} value={currency.id}>
                        {currency.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {fieldErrors?.currency_id && (
                    <Typography color="error" variant="caption">
                      {fieldErrors.currency_id}
                    </Typography>
                  )}
                </FormControl>
                <TextField
                  fullWidth
                  label="Instagram"
                  value={formData.instagram_link}
                  onChange={(e) =>
                    handleChange("instagram_link", e.target.value)
                  }
                  margin="normal"
                  error={Boolean(fieldErrors?.instagram_link)}
                  helperText={fieldErrors?.instagram_link}
                />
                <TextField
                  fullWidth
                  label="Facebook"
                  value={formData.facebook_link}
                  onChange={(e) =>
                    handleChange("facebook_link", e.target.value)
                  }
                  margin="normal"
                  error={Boolean(fieldErrors?.facebook_link)}
                  helperText={fieldErrors?.facebook_link}
                />
              </Grid>
              <Grid size={6}>
                <Autocomplete
                  multiple
                  options={languages}
                  getOptionLabel={(lang) => lang.name}
                  value={languages.filter((lang) =>
                    formData.languages.includes(lang.id)
                  )}
                  onChange={(event, value) =>
                    handleChange(
                      "languages",
                      value.map((lang) => lang.id)
                    )
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option.name}
                        {...getTagProps({ index })}
                        key={option.id}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Languages"
                      placeholder="Choose languages"
                      margin="normal"
                      error={Boolean(fieldErrors?.languages)}
                      helperText={fieldErrors?.languages}
                    />
                  )}
                />
                <TextField
                  fullWidth
                  label="Youtube"
                  value={formData.youtube_link}
                  onChange={(e) => handleChange("youtube_link", e.target.value)}
                  margin="normal"
                  error={Boolean(fieldErrors?.youtube_link)}
                  helperText={fieldErrors?.youtube_link}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid size={6}>
              <Typography variant="h4" color="black">
                Colors
              </Typography>
            </Grid>
            <Grid size={6}>
              <Typography variant="h4" color="black">
                Media
              </Typography>
            </Grid>
            <Grid container size={6}>
              <Grid size={12}>
                <ColorPickerField
                  label="Primary color"
                  color={formData.themeData.primary_color}
                  onColorChange={(newColor) =>
                    handleThemeChange("primary_color", newColor)
                  }
                />
              </Grid>
              <Grid size={6}>
                <ColorPickerField
                  label="Secondary color"
                  color={formData.themeData.secondary_color}
                  onColorChange={(newColor) =>
                    handleThemeChange("secondary_color", newColor)
                  }
                />
              </Grid>
              <Grid size={6}>
                <ColorPickerField
                  label="Tertiary color"
                  color={formData.themeData.tertiary_color}
                  onColorChange={(newColor) =>
                    handleThemeChange("tertiary_color", newColor)
                  }
                />
              </Grid>
            </Grid>

            <Grid size={3}>
              <Typography variant="caption">Home Screen</Typography>
              <ImageUpload
                imageUrl={formData.themeData.splash_screen_bg}
                onImageUpload={(url) =>
                  handleThemeChange("splash_screen_bg", url)
                }
                disabled={loading}
                uploadLabel="Home screen"
                sxBox={{ mt: 2 }}
              />
            </Grid>
            <Grid size={3}>
              <Typography variant="caption">Background</Typography>
              <ImageUpload
                imageUrl={formData.themeData.theme_bg}
                onImageUpload={(url) => handleThemeChange("theme_bg", url)}
                disabled={loading}
                uploadLabel="Background"
                sxBox={{ mt: 2 }}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="h4" color="black">
                Fonts
              </Typography>
            </Grid>
            <Grid size={4}>
              <Typography variant="subtitle1" sx={{ minWidth: "80px" }}>
                English
              </Typography>
              <Box>
                <FileUploadField
                  onFileUpload={(fileName, fontUrl) => {
                    handleThemeChange("font_url", fontUrl);
                    handleThemeChange("font_name", fileName);
                  }}
                  fileName={formData.themeData.font_name}
                />
              </Box>
            </Grid>
            {formData.languages.map((languageId) => {
              const lang = languages.find((l) => l.id === languageId);
              if (!lang) return null; // Ensure the language exists

              return (
                <Grid key={languageId} size={4}>
                  <Typography variant="subtitle1" sx={{ minWidth: "80px" }}>
                    {lang.name}
                  </Typography>
                  <Box>
                    <FileUploadField
                      onFileUpload={(fileName, fileUrl) =>
                        handleFontChange(languageId, fileName, fileUrl)
                      }
                      fileName={
                        formData.fontsData.find(
                          (f) => f.language_id === languageId
                        )?.font_name
                      }
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        )}
      </DialogContent>
      <DialogActions sx={{ mb: 2, ml: 1, justifyContent: "space-between" }}>
        <Box>
          <Button
            sx={{ px: 4, mx: 1 }}
            variant="outlined"
            onClick={handleClose}
            disabled={loading}
          >
            Close
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ px: 4 }}
          >
            {loading ? <CircularProgress size={24} /> : "Update"}
          </Button>
        </Box>
        <Box sx={{ mr: 2 }}>
          <Typography variant="caption" color="gray">
            License expiry date:{" "}
            <span style={{ fontWeight: "bold", color: "black" }}>
              {auth.user.restaurant.license_end_date
                ? new Date(
                    auth.user.restaurant.license_end_date
                  ).toLocaleDateString()
                : "N/A"}
            </span>
          </Typography>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SettingsForm;
