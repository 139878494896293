import axiosInstance from "./axiosInstance"; // Import the axios instance

export const loginRestaurant = async (restaurant_name) => {
  try {
    const response = await axiosInstance.post("/auth/restaurant", {
      restaurant_name,
    });

    return response.data; // Return the API response
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Login failed. Please try again."
    );
  }
};

export const loginAdmin = async (username, password) => {
  try {
    const response = await axiosInstance.post("/auth/login", {
      username,
      password,
    });
    return response.data; // Return the API response
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Admin login failed. Please try again."
    );
  }
};

export const logout = () => {
  localStorage.removeItem("token");
};
