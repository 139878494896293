import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { LoadingProvider } from "./contexts/LoadingContext";
import LoadingSpinner from "./components/LoadingSpinner";
import { SnackbarProvider } from "notistack";
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter } from "react-router-dom";
import { CustomThemeProvider } from "./theme/ThemeProvider";

// Get the root element
const rootElement = document.getElementById("root");

// Create a root and render the app
const root = ReactDOM.createRoot(rootElement);
root.render(
  <CustomThemeProvider>
    <BrowserRouter>
      <LoadingProvider>
        <AuthProvider>
          <SnackbarProvider
            dense
            maxSnack={3}
            autoHideDuration={2000}
            anchorOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            style={{
              fontSize: "12px",
              wordWrap: "break-word",
              width: "250px",
            }}
          >
            <LoadingSpinner />
            <App />
          </SnackbarProvider>
        </AuthProvider>
      </LoadingProvider>
    </BrowserRouter>
  </CustomThemeProvider>
);
