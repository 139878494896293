import axiosInstance from "./axiosInstance";

// Fetch items by menu ID
export const getItemsByMenuId = async (menuId) => {
  try {
    const response = await axiosInstance.get(`/items/menu/${menuId}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    console.error(`Error fetching items for menu ${menuId}:`, error);
    throw error; // Pass the error for further handling
  }
};

export const getItemById = async (itemId) => {
  try {
    const response = await axiosInstance.get(`/items/${itemId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching item with id ${itemId}:`, error);
    throw error;
  }
};
