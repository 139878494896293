import { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import DateRangePicker from "../../../components/DateRangePicker";

const StatisticsToolbar = ({
  title = "Statistics",
  searchPlaceholder = "Search",
  dropdownOptions = ["Top items 1", "Top items 2", "Top items 3"],
  onSearchChange,
  onDropdownChange,
  onDateRangeChange,
}) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [dropdownValue, setDropdownValue] = useState("");

  const handleSearchChange = (e) => {
    if (onSearchChange) onSearchChange(e.target.value);
  };

  const handleDropdownChange = (e) => {
    setDropdownValue(e.target.value);
    if (onDropdownChange) onDropdownChange(e.target.value);
  };

  const handleDateRangeChange = (newValue) => {
    setDateRange(newValue);
    if (onDateRangeChange) onDateRangeChange(newValue);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 1 }}>
      {/* Title */}
      <Typography variant="h4" sx={{ flexGrow: 1 }}>
        {title}
      </Typography>

      {/* Search Input */}
      <TextField
        placeholder={searchPlaceholder}
        size="small"
        onChange={handleSearchChange}
        sx={{
          flexGrow: 2,
          minWidth: 300,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#cccccc",
            },
          },
        }}
      />

      {/* Dropdown */}
      <FormControl
        size="small"
        sx={{
          minWidth: 150,
          backgroundColor: "#f5f5f5",
          borderRadius: 1,
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
        <Select
          value={dropdownValue}
          onChange={handleDropdownChange}
          displayEmpty
          sx={{
            width: "100%",
            "& .MuiSelect-select": {
              padding: "8px 14px",
            },
          }}
        >
          <MenuItem value="" disabled>
            Select an item
          </MenuItem>
          {dropdownOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Date Range Picker */}
      <DateRangePicker
        value={dateRange}
        onChange={handleDateRangeChange}
        renderInput={(startProps, endProps) => (
          <>
            <TextField {...startProps} size="small" sx={{ mr: 1 }} />
            <TextField {...endProps} size="small" />
          </>
        )}
      />
    </Box>
  );
};

export default StatisticsToolbar;
