import React from "react";
import { Box, TextField, Typography } from "@mui/material";

function ColorPickerField({ label, color, onColorChange }) {
  return (
    <Box>
      <Typography variant="caption">{label}</Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}>
        <TextField
          type="color"
          value={color}
          onChange={(e) => onColorChange(e.target.value)}
          sx={{
            width: "53px",
            height: "53px",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            border: "1px solid #ccc",
            '& input[type="color"]': {
              padding: 0,
              width: "70px",
              height: "70px",
            },
          }}
        />
        <TextField
          fullWidth
          value={color}
          onChange={(e) => onColorChange(e.target.value)}
        />
      </Box>
    </Box>
  );
}

export default ColorPickerField;
