import React, { useState, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { Box, CircularProgress, Typography, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { uploadFile as uploadMedia } from "../services/fileService";

const CircularProgressWithLabel = (props) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {props.value > 0 ? `${Math.round(props.value)}%` : "Uploading..."}
        </Typography>
      </Box>
    </Box>
  );
};

const ImageUpload = ({
  imageUrl,
  onImageUpload,
  disabled,
  sxBox = {},
  sxImg = {},
  uploadLabel = "Upload a file",
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);

  // Cache the image URL using useRef so it persists across renders
  const cachedImageUrl = useRef(imageUrl);
  const [imageLoaded, setImageLoaded] = useState(false); // Flag to track if image has been loaded

  // Only fetch and set the image when it is first provided
  useEffect(() => {
    if (imageUrl && !imageLoaded) {
      cachedImageUrl.current = imageUrl;
      setImageLoaded(true); // Set flag to prevent further loading
    }
  }, [imageUrl, imageLoaded]); // Effect runs only when imageUrl changes

  useEffect(() => {
    // Reset imageLoaded state when the imageUrl changes (if required)
    if (imageUrl !== cachedImageUrl.current) {
      setImageLoaded(false); // Allow imageUrl to be re-loaded
    }
  }, [imageUrl]);

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];

    // Define valid image and video file types
    const validImageTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/webp",
    ];
    const validVideoTypes = ["video/mp4", "video/quicktime"]; // mov files are usually recognized as "video/quicktime"

    // Check if the file is a valid image or video
    if (![...validImageTypes, ...validVideoTypes].includes(file.type)) {
      setError(
        "Invalid file type. Please upload a valid image (JPG, PNG, GIF, WEBP) or video (MP4, MOV)."
      );
      return; // Stop further processing if file is not valid
    }

    if (file) {
      setIsUploading(true);
      setUploadProgress(0);
      setError(null);

      try {
        const uploadedUrl = await uploadMedia(file, (progress) => {
          setUploadProgress(progress);
        });
        cachedImageUrl.current = uploadedUrl; // Cache the uploaded URL
        onImageUpload(uploadedUrl); // Pass the new URL to the parent
      } catch (err) {
        setError("Failed to upload file");
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleDeleteImage = () => {
    cachedImageUrl.current = null;
    onImageUpload(null); // Clear image in the parent component
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop,
    disabled,
  });

  return (
    <Box
      {...getRootProps()}
      sx={{
        border: cachedImageUrl.current ? "none" : "2px dashed",
        borderColor: cachedImageUrl.current ? "none" : "divider",
        textAlign: "center",
        cursor: "pointer",
        mb: 3,
        opacity: disabled ? 0.5 : 1,
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: 150,
        position: "relative",
        ...sxBox,
      }}
    >
      <input {...getInputProps()} disabled={disabled} />
      {isUploading ? (
        <CircularProgressWithLabel value={uploadProgress} />
      ) : cachedImageUrl.current ? (
        <>
          <Box
            component="img"
            src={cachedImageUrl.current}
            alt="Uploaded"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: 1,
              ...sxImg,
            }}
          />
          <IconButton
            onClick={handleDeleteImage}
            sx={{
              position: "absolute",
              bottom: 8,
              right: 8,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              color: "red",
            }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ) : (
        <>
          <IconButton sx={{ backgroundColor: "#4caf50", color: "white" }}>
            <AddIcon />
          </IconButton>
          <Typography variant="body2" sx={{ mt: 1 }}>
            {isDragActive ? "Drop the image here..." : uploadLabel}
          </Typography>
        </>
      )}
      {error && <Typography color="error">{error}</Typography>}
    </Box>
  );
};

export default ImageUpload;
