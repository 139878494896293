import React, { createContext, useContext, useState, useEffect } from "react";
import { getCurrencyFromToken } from "../utils/tokenUtils";

const CurrencyContext = createContext();

export const useCurrency = () => useContext(CurrencyContext);

export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState(getCurrencyFromToken());

  // Example effect to update the currency if the token changes
  useEffect(() => {
    const handleStorageChange = () => {
      setCurrency(getCurrencyFromToken());
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <CurrencyContext.Provider value={{ currency }}>
      {children}
    </CurrencyContext.Provider>
  );
};
