import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";

const TableData = ({}) => {
  return (
    <>
      <Box>
        {/* Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ py: 1 }}>No</TableCell>
                <TableCell sx={{ py: 1 }}>Image & Title</TableCell>
                <TableCell sx={{ py: 1 }}>Status</TableCell>
                <TableCell sx={{ py: 1 }}>Clicks</TableCell>
                <TableCell sx={{ py: 1 }}>Time spent</TableCell>
                <TableCell sx={{ py: 1 }}>Number of orders</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Example rows */}
              {[1, 2, 3, 4, 5].map((row) => (
                <TableRow key={row}>
                  <TableCell sx={{ py: 1.5 }}>{row}</TableCell>
                  <TableCell sx={{ py: 1.5 }}>Item {row}</TableCell>
                  <TableCell sx={{ py: 1.5 }}>Active</TableCell>
                  <TableCell sx={{ py: 1.5 }}>5k</TableCell>
                  <TableCell sx={{ py: 1.5 }}>32h</TableCell>
                  <TableCell sx={{ py: 1.5 }}>550</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Table Footer */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          p: 2,
          bgcolor: "background.paper",
          borderRadius: "0 0 4px 4px",
          borderTop: "1px solid #E0E0E0",
        }}
      >
        {/* Showing Selector */}
        <FormControl size="small" sx={{ minWidth: 80 }}>
          <Select defaultValue={5}>
            <MenuItem value={5}>Showing: 5</MenuItem>
            <MenuItem value={10}>Showing: 10</MenuItem>
            <MenuItem value={25}>Showing: 25</MenuItem>
          </Select>
        </FormControl>

        {/* Pagination */}
        <Pagination
          count={10}
          variant="outlined"
          color="primary"
          sx={{ mx: 2 }}
        />

        {/* Export Button */}
        <Button
          variant="outlined"
          sx={{ textTransform: "none", minWidth: "80px" }}
        >
          Export
        </Button>
      </Box>
    </>
  );
};

export default TableData;
