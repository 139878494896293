import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/startup"); // Redirect to startup page
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: "#eee",
        position: "relative",
        overflow: "hidden",
        padding: "0 20px",
      }}
    >
      {/* Background Illustration */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: 'url("/assets/404-background.svg")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          zIndex: -1,
          opacity: 0.2,
        }}
      />

      {/* Error Icon */}
      <ErrorOutlineIcon sx={{ fontSize: "6rem", color: "#ff5252", mb: 2 }} />

      {/* 404 Message with Gradient Text */}
      <Typography
        variant="h1"
        fontWeight="bold"
        sx={{
          fontSize: { xs: "3rem", md: "4rem" },
          background: "linear-gradient(90deg, #ff5252, #ff8c00)",
          WebkitBackgroundClip: "text",
          color: "transparent",
          mb: 2,
        }}
      >
        404
      </Typography>

      {/* Error Description */}
      <Typography
        variant="h6"
        color="textSecondary"
        sx={{
          mb: 4,
          fontSize: { xs: "1.2rem", md: "1.5rem" },
          maxWidth: "600px",
          opacity: 0.8,
        }}
      >
        Oops! The page you're looking for doesn't exist. You may have mistyped
        the address or the page has been moved.
      </Typography>

      {/* Back to Home Button */}
      <Button
        variant="contained"
        sx={{
          fontWeight: "bold",
          px: 4,
          py: 2,
          transition: "transform 0.3s ease-in-out",
          ":hover": {
            backgroundColor: "#3d5743",
            transform: "scale(1.05)",
          },
        }}
        onClick={handleGoHome}
      >
        Go to Startup Page
      </Button>
    </Box>
  );
};

export default NotFound;
