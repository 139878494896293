import axiosInstance from "./axiosInstance";

// Fetch menus from the backend
export const getMenus = async () => {
  try {
    const response = await axiosInstance.get("/menus");
    return response.data;
  } catch (error) {
    console.error("Error fetching menus:", error);
    throw error; // Pass the error for further handling
  }
};
