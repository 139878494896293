// AppRoutes.js
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import UnauthenticatedRoute from "./UnauthenticatedRoute";
import { routes } from "./routeConfig";

function AppRoutes() {
  return (
    <Routes>
      {routes.map(
        ({ path, element, roles, redirectTo, public: isPublic, providers }) => (
          <Route
            key={path}
            path={path}
            element={
              isPublic ? (
                <UnauthenticatedRoute redirectTo={redirectTo}>
                  {element}
                </UnauthenticatedRoute>
              ) : (
                <ProtectedRoute
                  element={element}
                  allowedRoles={roles}
                  providers={providers}
                  redirectTo={redirectTo}
                />
              )
            }
          />
        )
      )}
    </Routes>
  );
}

export default AppRoutes;
