export const filterItemsByCategory = (items, categoryId) => {
  // Filter items that contain the given categoryId
  const filteredItems = items.filter(
    (item) =>
      item.categories &&
      Array.isArray(item.categories) &&
      item.categories.some((cat) => cat.id === categoryId)
  );

  // Sort items based on the item_position of the category with the given categoryId
  filteredItems.sort((a, b) => {
    const categoryA = a.categories.find((cat) => cat.id === categoryId);
    const categoryB = b.categories.find((cat) => cat.id === categoryId);

    if (categoryA?.item_position != null && categoryB?.item_position != null) {
      return categoryA.item_position - categoryB.item_position;
    }
    return 0;
  });

  return filteredItems;
};
