import axios from "axios";
import { isTokenValid, getToken } from "../utils/tokenUtils";
import { getUserLocation } from "../services/geolocationService"; // Import the geolocation service
import { setupCache } from "axios-cache-interceptor";

const baseURL = process.env.REACT_APP_API_URL;

// Create an Axios instance
const axiosInstance = setupCache(
  axios.create({
    baseURL,
  }),
  {
    ttl: 1000 * 60 * 60 * 24,
    cachePredicate: {
      method: "GET",
    },
  }
);

// Setup Axios interceptors
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = getToken();

    // Skip token validation if the request is for login or we are on the login page
    if (
      config.url === "/auth/restaurant" ||
      window.location.pathname === "/login"
    ) {
      // Fetch location and attach to headers even for login
      try {
        const { latitude, longitude } = await getUserLocation();
        config.headers["x-user-latitude"] = latitude || "";
        config.headers["x-user-longitude"] = longitude || "";
      } catch (error) {
        console.warn(
          "Geolocation failed, proceeding without location headers."
        );
      }

      // config.headers.Authorization = token ? `Bearer ${token}` : "";
      return config;
    }

    // For other requests, check if the token is valid
    if (!isTokenValid()) {
      window.location.href = "/login"; // Redirect to login if token is invalid
      return Promise.reject("Token expired or invalid");
    }

    // Fetch location dynamically for other requests
    try {
      const { latitude, longitude } = await getUserLocation();
      config.headers["x-user-latitude"] = latitude || "";
      config.headers["x-user-longitude"] = longitude || "";
    } catch (error) {
      console.warn("Geolocation failed, proceeding without location headers.");
    }

    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // If a response error is due to unauthorized access, redirect to login
    // if (error.response?.status === 401) {
    //   window.location.href = "/login"; // Redirect to login on unauthorized error
    // }
    return Promise.reject(error);
  }
);

export default axiosInstance;
