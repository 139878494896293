import { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import DateRangePicker from "../../../components/DateRangePicker";

const TableToolbar = ({
  title = "Items",
  activeCount = 784,
  searchPlaceholder = "Search",
  dropdownOptions = ["Date added", "Name", "Orders"],
  onSearchChange,
  onDropdownChange,
  onDateRangeChange,
  onFilterClick,
}) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [dropdownValue, setDropdownValue] = useState("");

  const handleSearchChange = (e) => {
    if (onSearchChange) onSearchChange(e.target.value);
  };

  const handleDropdownChange = (e) => {
    setDropdownValue(e.target.value);
    if (onDropdownChange) onDropdownChange(e.target.value);
  };

  const handleDateRangeChange = (newValue) => {
    setDateRange(newValue);
    if (onDateRangeChange) onDateRangeChange(newValue);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", my: 2, gap: 1 }}>
      {/* Title with Active Count */}
      <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
        <Typography variant="h6" sx={{ mr: 1 }}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            backgroundColor: "#f5f5f5",
            padding: "2px 8px",
            borderRadius: "4px",
          }}
        >
          {activeCount} active
        </Typography>
      </Box>

      {/* Search Input */}
      <TextField
        placeholder={searchPlaceholder}
        size="small"
        onChange={handleSearchChange}
        sx={{
          minWidth: 200,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#cccccc",
            },
          },
        }}
      />

      {/* Date Range Picker */}
      <DateRangePicker
        value={dateRange}
        onChange={handleDateRangeChange}
        renderInput={(startProps, endProps) => (
          <>
            <TextField {...startProps} size="small" sx={{ mr: 1 }} />
            <TextField {...endProps} size="small" />
          </>
        )}
      />

      {/* Sort Dropdown */}
      <FormControl
        size="small"
        sx={{
          minWidth: 150,
          backgroundColor: "#f5f5f5",
          borderRadius: 1,
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
        <Select
          value={dropdownValue}
          onChange={handleDropdownChange}
          displayEmpty
          sx={{
            width: "100%",
            "& .MuiSelect-select": {
              padding: "8px 14px",
            },
          }}
        >
          <MenuItem value="" disabled>
            Sort by
          </MenuItem>
          {dropdownOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Filters Button */}
      <Button
        variant="outlined"
        onClick={onFilterClick}
        startIcon={<FilterListIcon />}
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#f5f5f5",
          borderColor: "#e0e0e0",
          color: "#333",
          textTransform: "none",
          fontWeight: 500,
          borderRadius: 1,
          "&:hover": {
            backgroundColor: "#e0e0e0",
            borderColor: "#cccccc",
          },
        }}
      >
        Filters
      </Button>
    </Box>
  );
};

export default TableToolbar;
