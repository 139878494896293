import React, { useState, useRef } from "react";
import { Box, Button, Typography, CircularProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { uploadFile } from "../services/fileService";

function FileUploadField({ onFileUpload, fileName }) {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedUrl, setUploadedUrl] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // List of allowed file extensions
    const allowedExtensions = ["woff", "woff2", "ttf", "otf"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    // Validate file extension
    if (!allowedExtensions.includes(fileExtension)) {
      setError(
        "Invalid file type. Please upload a file with one of the following extensions: woff, woff2, ttf, otf."
      );
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      return; // Stop further execution if the file type is invalid
    }

    setSelectedFile(file);
    setIsUploading(true);
    setError(null);

    try {
      const fileUrl = await uploadFile(file, (progress) => {
        setUploadProgress(progress);
      });
      setUploadedUrl(fileUrl);
      onFileUpload(file.name, fileUrl);
      setIsUploading(false);
      // Reset the input value to allow uploading the same file again
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (err) {
      setSelectedFile(null);
      setError(err?.response?.data?.message || "Error uploading file");
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleFileRemove = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    onFileUpload(null, null);
    setSelectedFile(null);
    setUploadedUrl(null);
    setUploadProgress(0);
    setError(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "50px",
        width: "100%",
      }}
    >
      <Button
        component="label"
        variant="contained"
        sx={{
          textTransform: "none",
          borderRadius: "10px 0 0 10px",
          bgcolor: "#ccc",
          border: "1px solid #999",
          m: 0,
          color: "#333",
          height: "100%",
          padding: 0,
          display: "flex",
          alignItems: "center",
          minWidth: "100px",
          borderRight: "none",
          boxShadow: "none",
        }}
        disabled={isUploading}
      >
        {isUploading ? (
          <CircularProgressWithLabel value={uploadProgress} />
        ) : (
          "Choose file"
        )}
        <input
          type="file"
          hidden
          onChange={handleFileChange}
          ref={fileInputRef}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
      </Button>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid #999",
          borderRadius: "0 10px 10px 0",
          padding: "0 8px",
          boxSizing: "border-box",
          height: "100%",
          width: "65%",
          m: 0,
          borderLeft: "none",
        }}
      >
        {selectedFile ? (
          <Box
            sx={{
              bgcolor: "#eee",
              paddingY: 0.5,
              paddingX: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
              maxWidth: "95%",
            }}
          >
            <Typography
              sx={{
                marginRight: 1,
                fontSize: "0.875rem",
                lineHeight: "1",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {selectedFile.name}
            </Typography>
            <DeleteIcon
              onClick={handleFileRemove}
              size="small"
              sx={{
                p: 0.5,
                height: "20px",
                width: "20px",
                color: "#333",
                cursor: "pointer",
              }}
            />
          </Box>
        ) : fileName ? (
          <Box
            sx={{
              bgcolor: "#eee",
              paddingY: 0.5,
              paddingX: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
              maxWidth: "95%",
            }}
          >
            <Typography
              sx={{
                marginRight: 1,
                fontSize: "0.875rem",
                lineHeight: "1",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {fileName?.name || fileName}
            </Typography>
            <DeleteIcon
              onClick={handleFileRemove}
              size="small"
              sx={{
                p: 0.5,
                height: "20px",
                width: "20px",
                color: "#333",
                cursor: "pointer",
              }}
            />
          </Box>
        ) : error ? (
          <Typography
            sx={{
              color: "red",
              fontSize: "0.875rem",
              lineHeight: "1",
            }}
          >
            {error}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
}

export default FileUploadField;

const CircularProgressWithLabel = (props) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} color="#333" />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {props.value > 0 ? `${Math.round(props.value)}%` : "Uploading..."}
        </Typography>
      </Box>
    </Box>
  );
};
