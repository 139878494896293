import { Box } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";

const BackgroundLogin = (props) => {
  const [isInView, setIsInView] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
        }
      },
      { threshold: 0.1 }
    );
    if (ref.current) observer.observe(ref.current);
    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, []);

  return (
    <>
      <Box
        ref={ref}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: "hidden",
          zIndex: -2,
          "&::before": {
            content: '""',
            position: "absolute",
            top: "-2%",
            left: "-2%",
            width: "104%",
            height: "104%",
            backgroundImage: isInView ? 'url("/login-bg.png")' : "none", // Lazy load background
            backgroundSize: {
              xs: "contain",
              sm: "cover",
            },
            backgroundPosition: "center",
            filter: {
              xs: "blur(5px)",
              sm: "blur(8px)",
            },
            zIndex: -1,
            transition: "opacity 0.5s ease-in-out", // Smooth transition when image loads
            opacity: isInView ? 1 : 0,
          },
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          zIndex: -1,
        }}
      />
    </>
  );
};

export default BackgroundLogin;
