export const getPlatform = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Detect iOS devices
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "mobile";
  }

  // Detect Android devices
  if (/android/i.test(userAgent)) {
    return "mobile";
  }

  // Check if it’s a tablet (using screen size as an additional check)
  const width = window.innerWidth;
  if (width >= 768 && width <= 1024) {
    return "tablet";
  }

  // Default platform
  return "desktop";
};
