import { Box, Button, Typography, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

export default function HeaderAnalytics({ activeTab, handleTabChange }) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mb: 2,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          className="contained"
          onClick={() => navigate("/dashboard")}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h3" sx={{ ml: 2 }}>
          Analytics
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 0.7,
          backgroundColor: "#f5f5f5",
          padding: 0.7,
          borderRadius: 1,
          height: 30,
          "& > .MuiButton-root": {
            borderRadius: 0.7,
          },
        }}
      >
        <Button
          variant={activeTab === "Menu" ? "contained" : "outlined"}
          onClick={() => handleTabChange("Menu")}
          className={activeTab === "Menu" ? "" : "inactive"}
        >
          Menu
        </Button>
        <Button
          variant={activeTab === "Categories" ? "contained" : "outlined"}
          onClick={() => handleTabChange("Categories")}
          className={activeTab === "Categories" ? "" : "inactive"}
        >
          Categories
        </Button>
        <Button
          variant={activeTab === "Items" ? "contained" : "outlined"}
          onClick={() => handleTabChange("Items")}
          className={activeTab === "Items" ? "" : "inactive"}
        >
          Items
        </Button>
      </Box>
    </Box>
  );
}
