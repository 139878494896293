import { Box, Skeleton } from "@mui/material";
const ItemSkeleton = () => {
  return Array.from({ length: 5 }).map((_, index) => (
    <Box key={index} sx={{ display: "flex", flexDirection: "column" }}>
      <Skeleton
        variant="circular"
        sx={{
          gap: 1,
          width: { xs: "140px", md: "114px" },
          height: { xs: "140px", md: "114px" },
        }}
      />
      <Skeleton
        variant="text"
        sx={{
          width: "100%",
          marginTop: "8px",
          height: "40px",
          fontSize: { xs: "14px", md: "12px" },
        }}
      />
    </Box>
  ));
};

export default ItemSkeleton;
