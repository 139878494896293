import React from "react";
import { useLoading } from "../contexts/LoadingContext"; // Use the loading context
import { CircularProgress, Box } from "@mui/material";

const LoadingSpinner = () => {
  const { isLoading } = useLoading();

  if (!isLoading) return null; // Don't show the spinner if loading is false

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark overlay behind the spinner
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999, // Ensure it stays on top of other elements
      }}
    >
      <CircularProgress size={60} style={{ color: "white" }} />
    </Box>
  );
};

export default LoadingSpinner;
