import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  TextField,
  Button,
  Box,
  IconButton,
  Switch,
  FormControlLabel,
  CircularProgress,
  Autocomplete,
  Chip,
  Grid2 as Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "../../contexts/AuthContext";
import { useDashboard } from "../../contexts/DashboardProvider";
import useCrud from "../../hooks/useCrud";
import ImageUpload from "../ImageUpload";
import CloseDialogButton from "../CloseDialogButton";

const initialState = (languages) => {
  return {
    name: "",
    description: "",
    price: "",
    image: "",
    video: "",
    preparation_time: "",
    categories: [],
    menus: [],
    allergens: [],
    calories: "",
    fat: "",
    carbs: "",
    proteins: "",
    languages: languages.map((lang) => ({
      language_id: lang.language_id,
      name: "",
      description: "",
    })),
    active: false,
  };
};

const ItemForm = ({ open, onClose, onSave, itemId = null }) => {
  const { languages } = useAuth();
  const { items: allItems, categories, menus, allergens } = useDashboard();
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState({ ...initialState(languages) });

  const [originalName, setOriginalName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isTogglingActive, setIsTogglingActive] = useState(false);

  const {
    createData,
    updateData,
    deleteData,
    getDataById,
    setActivationState,
    loading,
    error,
    fieldErrors,
  } = useCrud("/items");

  useEffect(() => {
    const loadItem = async () => {
      if (itemId) {
        try {
          const item = await getDataById(itemId);
          setOriginalName(item.name);
          setFormData({
            ...item,
            active: !!item.active,
          });
        } catch (error) {
          console.error("Error fetching item:", error);
        }
      } else {
        resetFormData();
      }
    };
    if (open) loadItem();
    else {
      resetFormData();
    }
  }, [itemId, open]);

  const resetFormData = () => {
    setFormData({ ...initialState(languages) });
    setOriginalName("");
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleLanguageChange = (languageId, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      languages: prevData.languages.map((lang) =>
        lang.language_id === languageId ? { ...lang, [field]: value } : lang
      ),
    }));
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      if (itemId) {
        await updateData(itemId, formData);
      } else {
        await createData(formData);
      }
      onSave(formData);
      onClose();
    } catch (err) {
      console.error("Error saving item:", err);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      setIsDeleting(true);
      try {
        await deleteData(itemId);
        onClose();
        onSave(itemId);
      } catch (err) {
        console.error("Error deleting item:", err);
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleToggleActiveState = async (event) => {
    setIsTogglingActive(true);
    const newActiveState = event.target.checked;
    try {
      await setActivationState(itemId, newActiveState);
      setFormData((prevData) => ({ ...prevData, active: newActiveState }));
    } catch (err) {
      console.error("Error updating active state:", err);
    } finally {
      setIsTogglingActive(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={!loading ? onClose : undefined}
      fullWidth
      maxWidth="md"
      disableEscapeKeyDown={loading}
    >
      <DialogTitle>
        {itemId ? `Edit "${originalName}" Item` : "Add Item"}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="English" />
            {languages.map((lang) => (
              <Tab key={lang.language_id} label={lang.name} />
            ))}
          </Tabs>
        </Box>
        <Box marginTop={2}>
          {activeTab === 0 ? (
            <>
              <TextField
                fullWidth
                label="Item Name"
                value={formData.name}
                onChange={(e) => handleChange("name", e.target.value)}
                required
                margin="normal"
              />
              <TextField
                fullWidth
                label="Price"
                value={formData.price}
                onChange={(e) => handleChange("price", e.target.value)}
                required
                margin="normal"
              />
              <ImageUpload
                imageUrl={formData.image}
                onImageUpload={(url) => handleChange("image", url)}
              />
              {/* Other fields for video, preparation time, etc. */}
            </>
          ) : (
            <>
              <TextField
                fullWidth
                label={`Name (${languages[activeTab - 1].name})`}
                value={formData.languages[activeTab - 1].name}
                onChange={(e) =>
                  handleLanguageChange(
                    languages[activeTab - 1].language_id,
                    "name",
                    e.target.value
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label={`Description (${languages[activeTab - 1].name})`}
                value={formData.languages[activeTab - 1].description}
                onChange={(e) =>
                  handleLanguageChange(
                    languages[activeTab - 1].language_id,
                    "description",
                    e.target.value
                  )
                }
                margin="normal"
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {itemId && (
          <FormControlLabel
            control={
              <Switch
                checked={formData.active}
                onChange={handleToggleActiveState}
                color="primary"
              />
            }
            label="Active Status"
          />
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {itemId && (
            <IconButton
              onClick={handleDelete}
              color="error"
              disabled={loading || isDeleting}
            >
              {isDeleting ? <CircularProgress size={16} /> : <DeleteIcon />}
            </IconButton>
          )}
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={loading || isSaving}
          >
            {isSaving ? (
              <CircularProgress size={24} />
            ) : itemId ? (
              "Update"
            ) : (
              "Save"
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ItemForm;
