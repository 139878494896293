import { useState } from "react";
import { Box } from "@mui/material";
import StatisticsChart from "./StatisticsChart";
import StatisticsToolbar from "./StatisticsToolbar";
import TableToolbar from "./TableToolbar";
import TableData from "./TableData";
import HeaderAnalytics from "./HeaderAnalytics";

const Analytics = () => {
  const [activeTab, setActiveTab] = useState("Items");
  // Handler for changing active tab
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSearchChange = (searchText) => {
    console.log("Search:", searchText);
  };

  const handleDropdownChange = (selectedItem) => {
    console.log("Dropdown selection:", selectedItem);
  };

  const handleDateRangeChange = (newDateRange) => {
    console.log("Date range:", newDateRange);
  };

  return (
    <Box
      sx={{ padding: "20px", height: "calc(100vh - 40px)", overflow: "hidden" }}
    >
      {/* Top Row: Go Back Button and Tab Buttons */}
      <HeaderAnalytics
        activeTab={activeTab}
        handleTabChange={handleTabChange}
      />

      {/* Second Row: Title, Search, Dropdown, and Date Range */}
      <StatisticsToolbar
        title="Statistics"
        searchPlaceholder="Search items"
        dropdownOptions={["Top items 1", "Top items 2", "Top items 3"]}
        onSearchChange={handleSearchChange}
        onDropdownChange={handleDropdownChange}
        onDateRangeChange={handleDateRangeChange}
      />

      {/* Third Row: Analytics Charts */}
      <StatisticsChart data={data} />

      {/* Filters for the Analytics Section */}
      <TableToolbar
        onSearchChange={(value) => console.log("Search:", value)}
        onDropdownChange={(value) => console.log("Dropdown:", value)}
        onDateRangeChange={(range) => console.log("Date Range:", range)}
        onFilterClick={() => console.log("Filters clicked")}
      />
      <TableData />
    </Box>
  );
};

export default Analytics;

const data = [
  {
    minLabel: "0",
    maxLabel: "9.82k",
    items: [
      { percentage: 32, label: "Tomato salad", color: "#48664e" },
      { percentage: 28, label: "Potato soup", color: "#EA8C26" },
      { percentage: 17, label: "Eggs Benedict with Salmon", color: "#EA8C26" },
      { percentage: 12, label: "Eggs Benedict w...", color: "#EA8C26" },
      { percentage: 8, label: "Tomato brusche...", color: "#EA8C26" },
    ],
  },
  {
    minLabel: "0h",
    maxLabel: "157h",
    items: [
      { percentage: 32, label: "Tomato salad", color: "#48664e" },
      { percentage: 28, label: "Potato soup", color: "#EA8C26" },
      { percentage: 17, label: "BBQ burgers", color: "#EA8C26" },
      { percentage: 12, label: "Eggs Benedict w...", color: "#EA8C26" },
      { percentage: 8, label: "Tomato brusche...", color: "#EA8C26" },
    ],
  },
  {
    minLabel: "0",
    maxLabel: "2.45k",
    items: [
      { percentage: 32, label: "Tomato salad", color: "#48664e" },
      { percentage: 28, label: "Potato soup", color: "#EA8C26" },
      { percentage: 17, label: "BBQ burgers", color: "#EA8C26" },
      { percentage: 12, label: "Eggs Benedict w...", color: "#EA8C26" },
      { percentage: 8, label: "Tomato brusche...", color: "#EA8C26" },
    ],
  },
];
