import React, { useState } from "react";
import { Box } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import { isImageUrl } from "../utils/isImageUrl";

const LazyImage = ({ src, alt, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const { restaurantLogo } = useAuth();
  let imageSource, objectFit;
  if (isImageUrl(src)) {
    imageSource = src;
    objectFit = props?.sx?.objectFit || "cover";
  } else if (isImageUrl(restaurantLogo)) {
    imageSource = restaurantLogo;
    objectFit = "contain";
  } else {
    imageSource = false;
  }

  if (imageSource)
    return (
      <Box
        component="img"
        src={imageSource} // Start with placeholder, then show the full image
        alt={alt}
        loading="lazy" // Lazy load the image
        onLoad={() => setLoaded(true)} // Trigger fade-in when full image loads
        sx={{
          transition: "opacity 0.5s ease-in-out", // Smooth fade-in transition
          opacity: loaded ? 1 : 0.5, // Reduce opacity while loading
          filter: loaded ? "blur(0px)" : "blur(10px)", // Blur effect while loading
          ...props.sx,
          objectFit: objectFit,
        }}
      />
    );
  else
    return (
      <Box
        sx={{
          backgroundColor: "#cccccc",
          ...props.sx,
        }}
      />
    );
};

export default LazyImage;
