import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  TextField,
  Button,
  Box,
  IconButton,
  Switch,
  FormControlLabel,
  CircularProgress,
  Autocomplete,
  Chip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "../../contexts/AuthContext";
import { useDashboard } from "../../contexts/DashboardProvider";
import useCrud from "../../hooks/useCrud";
import ImageUpload from "../ImageUpload";
import CloseDialogButton from "../CloseDialogButton";

const initialState = (languages) => {
  return {
    name: "",
    image: "",
    languages: languages.map((lang) => ({
      language_id: lang.language_id,
      name: "",
    })),
    items: [],
    active: false,
  };
};

const CategoryForm = ({ open, onClose, onSave, categoryId = null }) => {
  const { languages } = useAuth();
  const { items: allItems } = useDashboard();
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState({ ...initialState(languages) });

  const [originalName, setOriginalName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isTogglingActive, setIsTogglingActive] = useState(false);

  const {
    createData,
    updateData,
    deleteData,
    getDataById,
    setActivationState,
    loading,
    error,
    fieldErrors,
  } = useCrud("/categories");

  useEffect(() => {
    const loadCategory = async () => {
      if (categoryId) {
        try {
          const category = await getDataById(categoryId);

          setOriginalName(category.name);
          const categoryLanguages = category.languages || [];
          const populatedLanguages = languages.map((lang) => {
            const existingLanguage = categoryLanguages.find(
              (categoryLang) => categoryLang.language_id === lang.language_id
            );
            return {
              language_id: lang.language_id,
              name: existingLanguage ? existingLanguage.name : "",
            };
          });

          setFormData({
            name: category.name,
            image: category.image,
            languages: populatedLanguages,
            items: category.items ? category.items.map((item) => item.id) : [],
            active: !!category.active,
          });
        } catch (error) {
          console.error("Error fetching category:", error);
        }
      } else {
        resetFormData();
      }
    };
    if (open) loadCategory();
    else {
      setFormData({ ...initialState(languages) });
      setOriginalName("");
    }
  }, [categoryId, open]);

  const resetFormData = () => {
    setFormData({ ...initialState(languages) });
    setOriginalName("");
  };

  const handleNameChange = (e) => {
    setFormData((prevData) => ({ ...prevData, name: e.target.value }));
  };

  const handleImageUpload = (imageUrl) => {
    setFormData((prevData) => ({ ...prevData, image: imageUrl }));
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleLanguageChange = (languageId, value) => {
    setFormData((prevData) => ({
      ...prevData,
      languages: prevData.languages.map((lang) =>
        lang.language_id === languageId ? { ...lang, name: value } : lang
      ),
    }));
  };

  const handleItemsChange = (event, value) => {
    setFormData((prevData) => ({
      ...prevData,
      items: value.map((item) => item.id),
    }));
  };

  const handleSave = async () => {
    setIsSaving(true);
    const updatedData = {
      name: formData.name,
      image: formData.image,
      languages: formData.languages,
      ...(categoryId && { items: formData.items }),
    };

    try {
      const responseData = categoryId
        ? await updateData(categoryId, updatedData)
        : await createData(updatedData);
      onSave(updatedData);
      onClose();
    } catch (err) {
      console.error("Error saving category:", err);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      setIsDeleting(true);
      try {
        await deleteData(categoryId);
        onClose();
        onSave(categoryId);
      } catch (err) {
        console.error("Error deleting category:", err);
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleToggleActiveState = async (event) => {
    setIsTogglingActive(true);
    const newActiveState = event.target.checked;
    try {
      await setActivationState(categoryId, newActiveState);
      setFormData((prevData) => ({ ...prevData, active: newActiveState }));
    } catch (err) {
      console.error("Error updating active state:", err);
    } finally {
      setIsTogglingActive(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={!loading ? onClose : undefined}
      fullWidth
      maxWidth="sm"
      disableEscapeKeyDown={loading}
    >
      <DialogTitle>
        {categoryId ? `Edit "${originalName}" Category` : "Add Category"}
        <CloseDialogButton onClose={onClose} loading={loading} />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="English" sx={{ flex: 1 }} />
            {languages.map((lang) => (
              <Tab
                key={lang.language_id}
                label={lang.language_name}
                sx={{ flex: 1 }}
              />
            ))}
          </Tabs>
        </Box>

        <Box marginTop={2}>
          {activeTab === 0 ? (
            <>
              <ImageUpload
                key={formData.image}
                imageUrl={formData.image}
                onImageUpload={handleImageUpload}
                disabled={loading}
              />
              <TextField
                fullWidth
                label="Category name"
                value={formData.name}
                onChange={handleNameChange}
                margin="normal"
                sx={{ mb: 3 }}
                required
                error={!!fieldErrors.name}
                helperText={fieldErrors.name}
                disabled={loading}
              />
              {categoryId && (
                <Autocomplete
                  multiple
                  options={allItems}
                  getOptionLabel={(item) => item.name || "Unnamed Item"}
                  value={allItems.filter((item) =>
                    formData.items.includes(item.id)
                  )}
                  onChange={handleItemsChange}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option.name || "Unnamed Item"}
                        {...getTagProps({ index })}
                        key={option.id}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Items"
                      placeholder="Choose items"
                    />
                  )}
                  disabled={loading}
                />
              )}
            </>
          ) : (
            <TextField
              fullWidth
              label={`${languages[activeTab - 1]?.language_name} category name`}
              value={formData.languages[activeTab - 1]?.name || ""}
              onChange={(e) =>
                handleLanguageChange(
                  languages[activeTab - 1].language_id,
                  e.target.value
                )
              }
              margin="normal"
              sx={{ mb: 3 }}
              required
              error={!!fieldErrors[`languages.${activeTab - 1}.name`]}
              helperText={fieldErrors[`languages.${activeTab - 1}.name`]}
              disabled={loading}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          flexDirection: "column",
          alignItems: "flex-end",
          px: 3,
          mb: 2,
        }}
      >
        {categoryId && (
          <FormControlLabel
            control={
              <Switch
                checked={formData.active}
                onChange={handleToggleActiveState}
                color="primary"
                disabled={loading}
              />
            }
            label="Active Status"
          />
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            ...(categoryId && { flexDirection: "row-reverse" }),
          }}
        >
          {categoryId && (
            <IconButton
              onClick={handleDelete}
              sx={{
                color: "red",
                border: "1px solid #F4F4F5",
                borderRadius: "6px",
              }}
              disabled={loading || isDeleting}
            >
              {isDeleting ? <CircularProgress size={16} /> : <DeleteIcon />}
            </IconButton>
          )}
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={loading || isSaving}
            sx={{ px: 4 }}
          >
            {isSaving ? (
              <CircularProgress size={24} />
            ) : categoryId ? (
              "Update"
            ) : (
              "Save"
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryForm;
