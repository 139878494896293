import { Typography } from "@mui/material";

const HolomenuTitle = ({ title = "holomenu", style = {} }) => {
  return (
    <Typography
      variant="h1"
      sx={{
        color: "white",
        fontWeight: "bold",
        fontSize: {
          xs: "3rem",
          sm: "4rem",
        },
        letterSpacing: "0.1rem",
        mb: {
          xs: 2,
          sm: 5,
        },
        textAlign: "center",
        zIndex: 1, // Ensure content is above the background
        textTransform: "capitalize",
        ...style,
      }}
    >
      {title}
    </Typography>
  );
};

export default HolomenuTitle;
