import { Skeleton } from "@mui/material";

const CategorySkeletons = () => {
  return Array.from({ length: 7 }).map((_, index) => (
    <Skeleton
      key={index}
      variant="rectangular"
      sx={{
        position: "relative",
        textAlign: "center",
        width: { xs: "60%", md: "152px" }, // Fixed width for horizontal scroll
        height: { xs: "100%", md: "107px" },
        boxSizing: "border-box",
        overflow: "hidden",
        cursor: "pointer",
        flex: "0 0 auto",
      }}
    />
  ));
};

export default CategorySkeletons;
