// Login.js
import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import BackgroundLogin from "./backgroundLogin";
import HolomenuTitle from "../../components/holomenuTitle";
import LoginForm from "./loginForm";

const Login = () => {
  const [restaurantName, setRestaurantName] = useState("");
  const [error, setError] = useState(null);
  const { loginUser } = useAuth();

  const handleLogin = async (restaurant = restaurantName) => {
    try {
      await loginUser(restaurant);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const restaurantNameQuery = params.get("restaurant_name");

    if (restaurantNameQuery) {
      setRestaurantName(restaurantNameQuery);
      handleLogin(restaurantNameQuery);
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100svh",
        position: "relative",
        px: {
          xs: 2,
          sm: 0,
        },
      }}
    >
      <BackgroundLogin />
      <HolomenuTitle />
      <LoginForm
        restaurantName={restaurantName}
        setRestaurantName={setRestaurantName}
        handleLogin={handleLogin}
        error={error}
      />
    </Box>
  );
};

export default Login;
