import React, { createContext, useState, useContext } from "react";
import { useSnackbar } from "notistack";
import { createOrder } from "../services/orderService";

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const addToCart = async (item) => {
    try {
      // Check if the item already exists in the cart
      const itemExists = cartItems.some((cartItem) => cartItem.id === item.id);

      // Update the cart state
      setCartItems((prevItems) => {
        const existingItem = prevItems.find((i) => i.id === item.id);
        if (existingItem) {
          return prevItems.map((i) =>
            i.id === item.id ? { ...i, quantity: i.quantity + 1 } : i
          );
        }
        return [...prevItems, { ...item, quantity: 1 }];
      });

      enqueueSnackbar(`${item.name} is added to cart!`, { variant: "success" });

      // If the item is not already in the cart, create an order
      if (!itemExists) {
        try {
          const { data } = await createOrder({ item_id: item.id });
          console.log(data);
        } catch (error) {
          console.error("Error creating order:", error);
        }
      }
    } catch (error) {
      enqueueSnackbar(`Failed to add ${item.name} to the cart.`, {
        variant: "error",
      });
    }
  };

  const removeFromCart = (id) => {
    setCartItems((prevItems) => prevItems.filter((i) => i.id !== id));
  };

  const updateQuantity = (id, quantity) => {
    setCartItems((prevItems) =>
      prevItems.map((i) => (i.id === id ? { ...i, quantity: quantity } : i))
    );
  };
  const clearCart = () => {
    setCartItems([]); // Clear all items from the cart
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        updateQuantity,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
