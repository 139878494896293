import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CloseDialogButton = ({ onClose, loading }) => {
  return (
    <IconButton
      aria-label="close"
      onClick={onClose}
      disabled={loading}
      sx={{
        position: "absolute",
        right: 20,
        top: 14,
        height: "28px",
        width: "28px",
        borderRadius: 1,
        border: "1px solid",
        borderColor: (theme) => theme.palette.grey[200],
        color: (theme) => theme.palette.grey[500],
        "&:hover": {
          backgroundColor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.secondary.main,
        },
      }}
    >
      <CloseIcon sx={{ fontSize: "16px" }} />
    </IconButton>
  );
};

export default CloseDialogButton;
