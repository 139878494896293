import { useState, useRef, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ItemDetails from "../../Menu/ItemDetails";
import { getItemById } from "../../../services/itemService";
import { useLoading } from "../../../contexts/LoadingContext";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "../../../contexts/AuthContext";
import { isImageUrl } from "../../../utils/isImageUrl";
import LazyImage from "../../../components/lazyImage";
import ItemSkeleton from "../../../components/ItemSkeletons";
import EditButton from "../../../components/EditButton";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import useCrud from "../../../hooks/useCrud";
import ItemForm from "../../../components/forms/ItemForm";

const ItemTypes = { ITEM: "item" };

const ItemListAdmin = ({ items, selectedCategory, dimensions }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const { startLoading, stopLoading } = useLoading();
  const [isAnimating, setIsAnimating] = useState(false);
  const theme = useTheme();
  const { themeBg } = useAuth();
  const { reorderData } = useCrud("/items");
  const [itemOrder, setItemOrder] = useState(items);

  useEffect(() => {
    setItemOrder(items);
  }, [items]);

  const handleOpenOverlay = async (item) => {
    startLoading();
    try {
      const { data } = await getItemById(item.id);
      setSelectedItem(data);
      setShowOverlay(true);
      setIsAnimating(true);
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
    setTimeout(() => {
      setIsAnimating(false);
      setSelectedItem(null);
    }, 300);
  };

  const moveItem = (dragIndex, hoverIndex) => {
    const updatedItems = [...itemOrder];
    const [draggedItem] = updatedItems.splice(dragIndex, 1);
    updatedItems.splice(hoverIndex, 0, draggedItem);
    setItemOrder(updatedItems);
  };

  const handleDrop = async () => {
    const reorderedItems = itemOrder.map((item, index) => ({
      id: item.id,
      position: index + 1,
    }));

    try {
      await reorderData({
        category_id: selectedCategory.id,
        items: reorderedItems,
      });
    } catch (error) {
      console.error("Reordering failed:", error);
      setItemOrder(items);
    }
  };

  const correctDim = (
    padding = dimensions.itemListBoxPadding,
    screenSize = "md"
  ) => {
    const itemListWidth = `calc(100svw - ${padding * 2}px)`;
    const itemContainerWidth = `${
      dimensions.contentContainerWidth - padding
    }px`;
    let itemListHeight = "";
    if (screenSize === "md") {
      itemListHeight = 100 - dimensions.upperContentHeight + "svh";
    } else if (screenSize === "xs") {
      itemListHeight = `45svh`;
    }
    return { itemListWidth, itemContainerWidth, itemListHeight };
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: { xs: correctDim().itemListWidth, md: "100svw" },
          height: {
            xs: correctDim("1", "xs").itemListHeight,
            md: correctDim("1", "md").itemListHeight,
          },
          backgroundColor: theme.palette.secondary.main,
          backgroundImage: isImageUrl(themeBg) ? `url(${themeBg})` : "none",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          px: { xs: `${dimensions.itemListBoxPadding}px`, md: 0 },
          zIndex: 40,
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: { md: correctDim().itemContainerWidth },
          }}
        >
          <Box>
            {isAnimating && (
              <ItemDetails
                selectedItem={selectedItem}
                handleCloseOverlay={handleCloseOverlay}
                height={dimensions.upperContentHeight}
                isOpen={showOverlay}
              />
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "35px",
              }}
            >
              <SelectedCategoryName selectedCategory={selectedCategory} />
            </Box>
            <Items
              items={itemOrder}
              handleOpenOverlay={handleOpenOverlay}
              moveItem={moveItem}
              handleDrop={handleDrop}
            />
          </Box>
        </Box>
      </Box>
    </DndProvider>
  );
};

export default ItemListAdmin;

const SelectedCategoryName = ({ selectedCategory }) => {
  const theme = useTheme();
  return (
    <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
      {selectedCategory?.name}
    </Typography>
  );
};

const Items = ({ items, handleOpenOverlay, moveItem, handleDrop }) => {
  const [isItemFormOpen, setIsItemFormOpen] = useState(false);
  const [editingItemId, setEditingItemId] = useState(null);

  const theme = useTheme();
  const isLoading = items.length === 0;

  const handleOpenItemForm = (itemId = null) => {
    setEditingItemId(itemId);
    setIsItemFormOpen(true);
  };

  const handleCloseItemForm = () => {
    setIsItemFormOpen(false);
    setEditingItemId(null);
  };

  const handleSaveItem = (itemData) => {
    console.log("Item data to save:", itemData);
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: "16px",
        overflowX: "auto",
        py: "5px",
        flexGrow: 1,
        "&::-webkit-scrollbar": {
          height: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: theme.palette.primary.main,
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: theme.palette.secondary.main,
        },
      }}
    >
      <SharpOutlinedButton onClick={handleOpenItemForm} />
      <ItemForm
        onClose={handleCloseItemForm}
        onSave={handleSaveItem}
        open={isItemFormOpen}
        itemId={editingItemId}
      />
      {isLoading ? (
        <ItemSkeleton />
      ) : (
        items?.map((item, index) => (
          <Item
            key={item.id}
            item={item}
            index={index}
            handleOpenOverlay={handleOpenOverlay}
            moveItem={moveItem}
            handleDrop={handleDrop}
            handleOpenItemForm={handleOpenItemForm}
          />
        ))
      )}
    </Box>
  );
};

const Item = ({
  item,
  index,
  handleOpenOverlay,
  moveItem,
  handleDrop,
  handleOpenItemForm,
}) => {
  const { selectedLanguage } = useAuth();
  const theme = useTheme();
  const ref = useRef(null);

  const translatedName =
    item.languages?.find(
      (lang) => lang.language_id === selectedLanguage.language_id
    )?.name || item.name;

  const [, drop] = useDrop({
    accept: ItemTypes.ITEM,
    hover(draggedItem) {
      if (!ref.current) return;
      const dragIndex = draggedItem.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      moveItem(dragIndex, hoverIndex);
      draggedItem.index = hoverIndex;
    },
    drop: handleDrop,
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ITEM,
    item: { id: item.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Box
      ref={ref}
      sx={{
        opacity: isDragging ? 0.5 : 1,
        position: "relative",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      onClick={() => handleOpenOverlay(item)}
    >
      <EditButton onClick={() => handleOpenItemForm(item.id)} />
      <Box
        sx={{
          width: { xs: "140px", md: "114px" },
          height: { xs: "140px", md: "114px" },
          borderRadius: "50%",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: `3px solid ${theme.palette.tertiary.main}`,
          backgroundColor: theme.palette.primary.main,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          cursor: "pointer",
        }}
      >
        <LazyImage
          src={item.image}
          alt={translatedName}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            backgroundColor: "#cccccc",
          }}
        />
      </Box>

      <Typography
        variant="body2"
        sx={{
          width: "114px",
          marginTop: "8px",
          fontSize: { xs: "14px", md: "12px" },
        }}
      >
        {translatedName}
      </Typography>
    </Box>
  );
};

function SharpOutlinedButton({ onClick }) {
  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            border: "2px solid",
            width: { xs: "140px", md: "118px" },
            height: { xs: "140px", md: "118px" },
            borderRadius: "50%",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={onClick}
        >
          <AddIcon />
        </Button>
        <Typography
          variant="body2"
          sx={{
            width: "114px",
            marginTop: "8px",
            fontSize: { xs: "14px", md: "12px" },
          }}
        >
          Add item
        </Typography>
      </Box>
    </>
  );
}
