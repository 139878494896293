import { useRef, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAuth } from "../../../contexts/AuthContext";
import CategorySkeletons from "../../../components/CategorySkeletons";
import CategoryForm from "../../../components/forms/CategoryForm";
import LazyImage from "../../../components/lazyImage";
import EditButton from "../../../components/EditButton";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import useCrud from "../../../hooks/useCrud";

const ItemTypes = { CATEGORY: "category" };

const CategoryListAdmin = ({
  categories,
  selectedCategory,
  handleCategoryClick,
  menuId,
}) => {
  const theme = useTheme();
  const { selectedLanguage } = useAuth();
  const { reorderData } = useCrud("/categories");
  const [isCategoryFormOpen, setIsCategoryFormOpen] = useState(false);
  const [editingCategoryId, setEditingCategoryId] = useState(null);
  const [categoryOrder, setCategoryOrder] = useState([]);

  useEffect(() => {
    setCategoryOrder(categories);
  }, [categories]);

  const isLoading = categories.length === 0;

  const handleOpenCategoryForm = (categoryId = null) => {
    setEditingCategoryId(categoryId);
    setIsCategoryFormOpen(true);
  };

  const handleCloseCategoryForm = () => {
    setIsCategoryFormOpen(false);
    setEditingCategoryId(null);
  };

  const handleSaveCategory = (categoryData) => {
    console.log("Category data to save:", categoryData);
  };

  const moveCategoryItem = (dragIndex, hoverIndex) => {
    const updatedCategories = [...categoryOrder];
    const [draggedItem] = updatedCategories.splice(dragIndex, 1);
    updatedCategories.splice(hoverIndex, 0, draggedItem);
    setCategoryOrder(updatedCategories);
  };

  const handleDrop = async () => {
    const reorderedItems = {
      menu_id: menuId,
      categories: categoryOrder.map((category, index) => ({
        id: category.id,
        position: index + 1,
      })),
    };

    try {
      await reorderData(reorderedItems);
    } catch (error) {
      console.error("Reordering failed:", error);
      setCategoryOrder(categories); // Reset to original order on failure
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box
        sx={{
          display: "flex",
          flexWrap: { xs: "nowrap", md: "wrap" },
          alignContent: "start",
          gap: 2,
          padding: 0,
          my: 2,
          width: "100%",
          height: { xs: "60%", md: "72%" },
          overflowY: "auto",
          overflowX: { xs: "auto", md: "hidden" },
          "&::-webkit-scrollbar": {
            height: "6px",
            width: "6px",
            display: "none",
            borderRadius: "20px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
            height: "6px",
            width: "6px",
            display: "none",
          },
          "&::-webkit-scrollbar-track": {
            borderRadius: "20px",
            backgroundColor: theme.palette.primary.main,
            height: "6px",
            width: "6px",
            display: "none",
          },
        }}
      >
        <Button
          variant="outlined"
          sx={{
            flexDirection: "column",
            gap: 1,
            borderRadius: 0,
            width: "152px",
            color: "white",
            borderColor: "white",
            boxSizing: "border-box",
          }}
          onClick={() => handleOpenCategoryForm(null)}
        >
          <AddIcon />
          Add Category
        </Button>
        <CategoryForm
          open={isCategoryFormOpen}
          onClose={handleCloseCategoryForm}
          onSave={handleSaveCategory}
          categoryId={editingCategoryId}
        />
        {isLoading ? (
          <CategorySkeletons />
        ) : (
          categoryOrder.map((category, index) => (
            <CategoryAdmin
              key={category.id}
              category={category}
              handleCategoryClick={handleCategoryClick}
              theme={theme}
              selectedCategory={selectedCategory}
              selectedLanguage={selectedLanguage}
              handleOpenCategoryForm={handleOpenCategoryForm}
              index={index}
              moveCategoryItem={moveCategoryItem}
              handleDrop={handleDrop}
            />
          ))
        )}
      </Box>
    </DndProvider>
  );
};

export default CategoryListAdmin;

const CategoryAdmin = ({
  category,
  handleCategoryClick,
  theme,
  selectedCategory,
  selectedLanguage,
  handleOpenCategoryForm,
  index,
  moveCategoryItem,
  handleDrop,
}) => {
  const ref = useRef(null);
  const translatedName =
    category.languages?.find(
      (lang) => lang.language_id === selectedLanguage.language_id
    )?.name || category.name;

  const [, drop] = useDrop({
    accept: ItemTypes.CATEGORY,
    hover(item) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      moveCategoryItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: handleDrop,
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CATEGORY,
    item: { id: category.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Box
      ref={ref}
      sx={{
        opacity: isDragging ? 0.5 : 1,
        position: "relative",
        textAlign: "center",
        width: { xs: "60%", md: "152px" },
        height: { xs: "100%", md: "107px" },
        boxSizing: "border-box",
        border:
          selectedCategory.id === category.id
            ? `3px solid ${theme.palette.secondary.main}`
            : "none",
        overflow: "hidden",
        backgroundColor: "#ccc7bf",
        cursor: "pointer",
        flex: "0 0 auto",
      }}
      onClick={() => {
        handleCategoryClick(category.id);
      }}
    >
      <EditButton onClick={() => handleOpenCategoryForm(category.id)} />
      <LazyImage
        src={category.image}
        alt={translatedName}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: -1,
          display: "flex",
          justifyContent: "start",
          px: "5px",
          alignItems: "center",
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          backdropFilter: "blur(4px)",
          color: "#fff",
          height: { xs: "50px", md: "31px" },
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: { xs: "16px", md: "12px" },
            textAlign: "left",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {translatedName}
        </Typography>
      </Box>
    </Box>
  );
};
