// src/pages/MenuPage.js
import React from "react";
import { Box } from "@mui/material";
import MenuListAdmin from "./MenuListAdmin";
import ItemListAdmin from "./itemListAdmin";
import HeaderSection from "../../../components/HeaderSection";
import CategoryListAdmin from "./CategoryListAdmin";
import { useTheme } from "@mui/material/styles";
import { useDashboard } from "../../../contexts/DashboardProvider";

const dimensions = {
  contentContainerWidth: 992,
  itemListBoxPadding: 16,
  upperContentHeight: 68,
};

const calculateDime = (screenSize = "md") => {
  const maxWidth = dimensions.contentContainerWidth + "px";
  let height = "";
  if (screenSize === "md") {
    height = `${dimensions.upperContentHeight}svh`;
  } else if (screenSize === "xs") {
    height = `55svh`;
  }
  return { maxWidth, height };
};

const Dashboard = () => {
  const theme = useTheme();

  const {
    menus,
    activeMenu,
    handleMenuClick,
    categories,
    selectedCategory,
    handleCategoryClick,
    filteredItems,
  } = useDashboard();

  return (
    <Box
      sx={{
        display: "flex",
        height: "100svh",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          maxWidth: { md: calculateDime("md").maxWidth },
          width: "100%",
        }}
      >
        <Box
          sx={{
            height: {
              xs: calculateDime("xs").height,
              md: calculateDime("md").height,
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 0,
            m: 0,
            px: { xs: "16px", md: 0 },
          }}
        >
          <HeaderSection />
          <MenuListAdmin
            menus={menus}
            activeMenu={activeMenu}
            handleMenuClick={handleMenuClick}
          />
          <CategoryListAdmin
            categories={categories}
            selectedCategory={selectedCategory}
            handleCategoryClick={handleCategoryClick}
            menuId={activeMenu}
          />
        </Box>
        <ItemListAdmin
          items={filteredItems}
          selectedCategory={selectedCategory}
          dimensions={dimensions}
        />
      </Box>
    </Box>
  );
};

export default Dashboard;
