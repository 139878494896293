import { Box, Typography } from "@mui/material";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu"; // Icon for the 'Tap to open menu'
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const MenuButton = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleMenuClick = () => navigate("/menu");
  return (
    <Box
      sx={{
        borderRadius: "50%",
        border: "1.5px solid #A1A1AA",
        padding: "30px",
        mb: 6,
        width: "165px", // Width and height of the inner circle
        height: "165px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        cursor: "pointer",
        p: 0,
        transition: "transform 0.5s ease-in-out",
        ":hover": {
          transform: "scale(1.05)",
        },
        "::before": {
          content: '""',
          position: "absolute",
          border: "1px solid #D4D4D8", // First outer circle
          width: "200px",
          height: "200px",
          borderRadius: "50%",
        },
        "::after": {
          content: '""',
          position: "absolute",
          border: ".5px solid #E4E4E7", // Second outer circle
          width: "230px",
          height: "230px",
          borderRadius: "50%",
        },
      }}
      onClick={handleMenuClick}
    >
      <RestaurantMenuIcon
        sx={{
          width: "30px",
          height: "30px",
          color: theme.palette.primary.main,
        }}
      />
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.primary.main,
          mt: 1,
          fontWeight: "bold",
        }}
      >
        Tap to open menu
      </Typography>
    </Box>
  );
};

export default MenuButton;
