import axiosInstance from "./axiosInstance";

// Fetch menus from the backend
export const createActivities = async (data) => {
  try {
    const response = await axiosInstance.post("/activities", data);
    return response.data;
  } catch (error) {
    console.error("Error creating activities:", error);
    throw error;
  }
};
