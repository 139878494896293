import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";
import { getPlatform } from "../utils/getPlatform";
import { createActivities } from "../services/activityService";

const ActivityContext = createContext();
export const useActivity = () => useContext(ActivityContext);

const platform = getPlatform();

const ACTIVITY_TIMEOUT = 30000; // 30 seconds inactivity for each activity
const DATA_SEND_TIMEOUT = 60000; // 1 minute inactivity for sending data
const activityTypes = {
  menus: "menus",
  categories: "categories",
  items: "items",
};

// Utility function to create initial timer state
const createInitialTimersState = () => {
  return Object.keys(activityTypes).reduce(
    (acc, type) => ({ ...acc, [type]: null }),
    {}
  );
};

export const ActivityProvider = ({ children }) => {
  const [activities, setActivities] = useState([]);
  const [currentActivity, setCurrentActivity] = useState({
    menu: null,
    category: null,
    item: null,
  });

  const timers = useRef(createInitialTimersState());
  const inactivityTimeouts = useRef(createInitialTimersState());
  const dataSendTimeout = useRef(null);

  const [shouldSend, setShouldSend] = useState(false);

  // Helper to start a timer for a given activity
  const startTimer = (activityType, activityId) => {
    console.log(`Starting timer for ${activityType} with id: ${activityId}`);
    const startTime = Date.now();

    const intervalId = setInterval(() => {
      const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
      setActivities((prevActivities) => {
        const index = prevActivities.findIndex(
          (activity) =>
            activity.activity_id === activityId &&
            activity.activity_type === activityType
        );
        if (index >= 0) {
          const updatedActivities = [...prevActivities];
          updatedActivities[index].time_spent = elapsedTime;
          return updatedActivities;
        }
        return [
          ...prevActivities,
          {
            activity_id: activityId,
            activity_type: activityType,
            platform,
            time_spent: elapsedTime,
          },
        ];
      });
    }, 1000); // Update every second

    // Store both intervalId and startTime in timers.current
    timers.current[activityType] = { intervalId, startTime };
  };

  // Helper to stop a timer for a given activity
  const stopTimer = (activityType) => {
    const timer = timers.current[activityType];
    if (timer && timer.intervalId) {
      clearInterval(timer.intervalId);
      timers.current[activityType] = null;
      console.log(`Timer for ${activityType} stopped`);
    }
  };

  // Reset inactivity timeouts for all activity types
  const resetInactivityTimeouts = () => {
    Object.keys(inactivityTimeouts.current).forEach((activityType) => {
      clearTimeout(inactivityTimeouts.current[activityType]);
      inactivityTimeouts.current[activityType] = setTimeout(() => {
        console.log(
          `${activityType} inactivity timeout, stopping ${activityType} timer`
        );
        stopTimer(activityType);
      }, ACTIVITY_TIMEOUT);
    });
  };

  // Reset the timeout to send activities to the server
  const resetDataSendTimeout = () => {
    clearTimeout(dataSendTimeout.current);
    dataSendTimeout.current = setTimeout(() => {
      console.log(
        "1 minute of inactivity, preparing to send activities to server"
      );

      // Call saveFinalActivities to ensure all timers are stopped and activities are finalized
      saveFinalActivities();

      setShouldSend(true); // Trigger the sending of activities after finalization
    }, DATA_SEND_TIMEOUT);
  };

  // Save final activities and ensure the latest state is used
  const saveFinalActivities = () => {
    setActivities((prevActivities) => {
      const updatedActivities = prevActivities.map((activity) => {
        const timer = timers.current[activity.activity_type];
        if (timer === null) {
          return {
            ...activity,
            time_spent: Math.floor(activity.time_spent),
          };
        }
        return activity;
      });

      // Ensure missing final state for any activity type is added
      Object.keys(timers.current).forEach((activityType) => {
        const timer = timers.current[activityType];
        if (
          !updatedActivities.some((a) => a.activity_type === activityType) &&
          currentActivity[activityType] &&
          timer
        ) {
          updatedActivities.push({
            activity_id: currentActivity[activityType],
            activity_type: activityType,
            platform,
            time_spent: Math.floor((Date.now() - timer.startTime) / 1000),
          });
        }
      });

      return updatedActivities;
    });
  };

  // Use `useEffect` to handle side effects after activities state updates
  useEffect(() => {
    if (shouldSend) {
      sendActivitiesToServer();
      setShouldSend(false); // Reset the send trigger after sending
    }
  }, [shouldSend, activities]);

  // Function to send activities to the server
  const sendActivitiesToServer = async () => {
    try {
      console.log("Sending activities to server...");
      await createActivities(activities);
      console.log("Activities sent to server successfully");

      // Reset the states and refs
      resetStatesAndRefs();
    } catch (error) {
      console.error("Failed to send activities", error);
    }
  };

  // Generalized function to handle activity tracking
  const trackActivity = (activityType, activityId) => {
    console.log(`Tracking ${activityType} with id: ${activityId}`);
    resetInactivityTimeouts(); // Reset inactivity timers
    resetDataSendTimeout(); // Reset data sending timeout

    // Stop all lower-level timers when switching
    const hierarchy = ["menus", "categories", "items"];
    const activityIndex = hierarchy.indexOf(activityType);

    hierarchy.slice(activityIndex).forEach((type) => {
      if (currentActivity[type] !== activityId) {
        stopTimer(type);
      }
    });

    // Start the timer for the new activity
    startTimer(activityType, activityId);

    // Update the current activity state
    const newActivityState = { ...currentActivity };
    hierarchy.forEach((type, index) => {
      newActivityState[type] = index <= activityIndex ? activityId : null;
    });
    setCurrentActivity(newActivityState);
  };

  // Public tracking methods for menus, categories, and items
  const trackMenu = (menuId) => trackActivity("menus", menuId);
  const trackCategory = (categoryId) => trackActivity("categories", categoryId);
  const trackItem = (itemId) => trackActivity("items", itemId);

  // Reset states and refs after sending data
  const resetStatesAndRefs = () => {
    // Reset activities state
    setActivities([]);

    // Reset current activity state
    setCurrentActivity({
      menu: null,
      category: null,
      item: null,
    });

    // Reset timers and inactivity timeouts
    Object.keys(timers.current).forEach((type) => {
      const timer = timers.current[type];
      if (timer && timer.intervalId) {
        clearInterval(timer.intervalId); // Clear the interval using intervalId
      }
      timers.current[type] = null;
    });
    Object.keys(inactivityTimeouts.current).forEach((type) => {
      clearTimeout(inactivityTimeouts.current[type]);
      inactivityTimeouts.current[type] = null;
    });

    // Reset data send timeout ref
    clearTimeout(dataSendTimeout.current);
    dataSendTimeout.current = null;
  };

  return (
    <ActivityContext.Provider
      value={{
        activities,
        trackMenu,
        trackCategory,
        trackItem,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};

export default ActivityContext;
