import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
// import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import { IconButton } from "@mui/material";

const EditButton = ({ onClick, sx = {} }) => {
  return (
    <IconButton
      sx={{
        backgroundColor: "#000",
        color: "#fff",
        borderRadius: "50%",
        position: "absolute",
        right: 5,
        top: 7,
        height: "26px",
        width: "26px",
        zIndex: 10,
        border: "1.5px solid #fff",
        ...sx,
      }}
      onClick={onClick}
    >
      <BorderColorRoundedIcon sx={{ height: "16px", width: "16px" }} />
    </IconButton>
  );
};

export default EditButton;
