import { Skeleton } from "@mui/material";

const MenuSkeletons = () => {
  return Array.from({ length: 5 }).map((_, index) => (
    <Skeleton
      key={index}
      variant="text"
      sx={{
        borderRadius: 0,
        mx: 1,
        px: "40px",
        cursor: "pointer",
        fontSize: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "50px",
        height: "46px",
      }}
    />
  ));
};

export default MenuSkeletons;
