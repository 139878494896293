// src/pages/MenuPage.js
import React, { useContext } from "react";
import { Box } from "@mui/material";
import HeaderSection from "../../components/HeaderSection";
import { MenuContext } from "../../contexts/MenuContext";
import MenuListUser from "./MenuListUser";
import ItemListUser from "./ItemListUser";
import CategoryListUser from "./CategoryListUser";
import { useTheme } from "@mui/material/styles";

const dimensions = {
  contentContainerWidth: 992,
  itemListBoxPadding: 16,
  upperContentHeight: 68,
};

const calculateDime = (screenSize = "md") => {
  const maxWidth = dimensions.contentContainerWidth + "px";
  let height = "";
  if (screenSize === "md") {
    height = `${dimensions.upperContentHeight}svh`;
  } else if (screenSize === "xs") {
    height = `55svh`;
  }
  return { maxWidth, height };
};

const MenuPage = () => {
  const theme = useTheme();
  const {
    menus,
    filteredItems,
    categories,
    activeMenu,
    selectedCategory,
    handleMenuClick,
    handleCategoryClick,
  } = useContext(MenuContext);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100svh",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          maxWidth: { md: calculateDime("md").maxWidth },
          width: "100%",
        }}
      >
        <Box
          sx={{
            height: {
              xs: calculateDime("xs").height,
              md: calculateDime("md").height,
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 0,
            m: 0,
            px: { xs: "16px", md: 0 },
          }}
        >
          <HeaderSection />
          <MenuListUser
            menus={menus}
            activeMenu={activeMenu}
            handleMenuClick={handleMenuClick}
          />
          <CategoryListUser
            categories={categories}
            selectedCategory={selectedCategory}
            handleCategoryClick={handleCategoryClick}
          />
        </Box>
        <ItemListUser
          items={filteredItems}
          selectedCategory={selectedCategory}
          dimensions={dimensions}
        />
      </Box>
    </Box>
  );
};

export default MenuPage;
